/* 
   Name: Habibur Rahman
   Project: React Landing Page
   Date: October 30, 2023
   File: HeroSection.module.css
*/

/* Hero styling */
.hero {
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  padding:  50px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Styling for the content within the hero section */
.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

/* Styling for the main headline */
.hero-title {
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #fff;
}

/* Styling for the sub-headline */
.hero-description {
  font-size: 1.8rem;
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-style: italic;
  color: #fff;
}

/* Styling for the call-to-action button */
.cta-button {
  background-color: #fff;
  color: var(--primary-color);
  font-size: 1.4rem;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: uppercase;
}

/* Change button color on hover */
.cta-button:hover {
  
  background-color: var(--secondary-color);
  color: #fff;
}

/* Styling for the animated image */
.animated-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0px 50px;
 
  max-height: 500px;
  object-fit: cover;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .hero {
    padding: 80px 0;
  }

  .hero-title {
    font-size: 3rem;
  }

  .animated-image {
    margin: 0px 0px;
  }

  .hero-description {
    font-size: 1.5rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 1.2rem;
  }

  .animated-image {
    margin: 0px 0px;
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .animated-image {
    margin: 0px 0px;
  }

  .hero {
    padding: 60px 0;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1.2rem;
  }

  .cta-button {
    padding: 8px 16px;
    font-size: 1rem;
  }

  /* Rearrange the layout for small devices */
  .hero-content {
    flex-direction: column;
    align-items: center;
  }
}
