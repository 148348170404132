/* 
   Name: Habibur Rahman
   Project: React Landing Page
   Date: October 30, 2023
   File: AboutSection.module.css
*/

/* About Us styling */
.aboutUs {
  background-color: #f5f5f5;
  padding: 80px 0;
  text-align: center;
  overflow: hidden;
}

/* Styling for the content within the About Us section */
.aboutUsContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Styling for the main title */
.aboutUsTitle {
  font-size: 2.5rem;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 20px;
  transition: color 0.3s;
}

/* Styling for the description text */
.aboutUsDescription {
  font-size: 1.5rem;
  margin-bottom: 20px;
  transition: color 0.3s;
}

/* Styling for the image */
.aboutUsImage {
  max-width: 100%;
  height: auto;
  display: block;
  max-height: 650px;
  object-fit: cover;
  transition: transform 0.3s;
}

/* Hover effects */
.aboutUsImage:hover {
  transform: scale(1.02);
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .aboutUsContent {
    flex-direction: column;
    text-align: center;
  }
  .aboutUsTitle {
    font-size: 2rem;
  }
  .aboutUsDescription {
    font-size: 1.2rem;
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .aboutUsTitle {
    font-size: 1.8rem;
  }
  .aboutUsDescription {
    font-size: 1.1rem;
  }
  .aboutUsImage {
    max-height: 300px;
  }
}
