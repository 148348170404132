/* 
   Name: Habibur Rahman
   Project: React Landing Page
   Date: October 30, 2023
   File: NavBar.module.css
*/

/* Styling for the NavBar */
.navbar {
  background-color: #fff;
  padding: 10px 0;
}

/* Styling for the content within the NavBar */
.navbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Styling for the logo */
.logo img {
  width: 90px;
}

/* Styling for the navigation links */
.links ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.links li {
  margin-right: 20px;
}

.links a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  margin: 5px 5px 5px 5px;
}

.links a:hover {
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

/* Styling for the mobile icon */
.mobileIcon {
  display: none;
  cursor: pointer;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .links ul {
    display: none;
  }

  .mobileIcon {
    display: block;
    font-size: 1.5rem;
    color: var(--primary-color);
  }

  .open {
    display: flex !important;
    flex-direction: column;
    background-color: var(--secondary-color);
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
  }

  .open li {
    margin: 10px 0;
    text-align: center;
  }

  .open a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }

  .open a:hover {
    color: #000;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    transition: background-color 0.3s;
  }
}
/* Media query for mobile devices */
@media (max-width: 480px) {
  .links ul {
    display: none;
  }

  .mobileIcon {
    display: block;
    font-size: 1.5rem;
    color: #000;
  }
  .open {
    display: flex !important;
    flex-direction: column;
    background-color: #0074d9;
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
  }

  .open li {
    margin: 10px 0;
    text-align: center;
  }

  .open a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }

  .open a:hover {
    color: #fff;
    background-color: #0056b3;
    border-radius: 5px;
    padding: 5px 10px;
    transition: background-color 0.3s;
  }
}
