/* 
   Name: Habibur Rahman
   Project: React Landing Page
   Date: October 30, 2023
   File: Services.module.css
*/

/* Service styling */
.service {
  background-color: #f9f9f9;
  padding: 20px 0;
  text-align: center;
  overflow: hidden;
}

/* Styling for the title */
.serviceTitle {
  font-size: 2.5rem;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 40px;
}

/* Styling for service items */
.serviceItems {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
}

/* Styling for individual service items */
.serviceItem {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Styling for service item icons */
.serviceIcon {
   max-width: 100px;
   max-height: 800px;
  font-size: 3.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

/* Styling for service item titles */
.serviceItemTitle {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Styling for service item descriptions */
.serviceItemDescription {
  font-size: 1.2rem;
  color: #666;
}

/* Hover effects */
.serviceItem:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .serviceItems {
    grid-template-columns: repeat(2, 1fr);
  }

  .serviceItem {
    padding: 15px;
    font-size: 1rem;
    line-height: 1.3;
    margin-bottom: 20px;
    border-radius: 8px;
  }
}
/* Responsive styles for mobile */
@media (max-width: 480px) {
  .serviceItems {
    grid-template-columns: 1fr;
  }

  .serviceItem {
    width: 100%;
    margin: 10px 0;
    padding: 15px;
    font-size: 1rem;
  }

  .serviceIcon {
    font-size: 3rem;
  }
}
