/* 
   Name: Habibur Rahman
   Project: React Landing Page
   Date: October 30, 2023
   File: Portfolio.module.css
*/

/* Portfolio styling */
.portfolio {
  background-color: #f5f5f5;
  padding: 20px 0;
  text-align: center;
  overflow: hidden;
}

.cardTop{
  height: 80%;
  margin-bottom: 30px;
}

/* Styling for the title */
.portfolioTitle {
  font-size: 2.5rem;
  color: var(--primary-color);
  font-weight: bold;
  /* margin-bottom: 40px; */
  transition: color 0.3s;
}
.portfolioSubTitle {
  font-size: 1.5rem;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 40px;
  transition: color 0.3s;
  font-style: italic;
  overflow-x: hidden;
}

/* Styling for individual projects */
.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.project {
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.project:hover {
  transform: scale(1.05);
}

/* Styling for project images */
.projectImage {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s;
}

/* Styling for project titles */
.projectTitle {
  font-size: 1.5rem;
  color: #333;
  margin: 20px 0;
}

/* Styling for project descriptions */
.projectDescription {
  font-size: 1.2rem;
  color: #777;
  margin: 20px 0;
}

/* Styling for project links */
.projectLink {
  display: inline-block;
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin: 20px 0;
  transition: background-color 0.3s;
}

.projectLink:hover {
  background-color: var(--secondary-color);
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .project {
    max-width: 45%;
    transition: transform 0.3s;
  }
  .projectTitle {
    font-size: 1.2rem;
  }
  .projectDescription {
    font-size: 1rem;
  }
}

/* Responsive styles for mobile */
@media (max-width: 480px) {
  .project {
    max-width: 100%;
    transition: transform 0.3s;
  }
  .projectTitle {
    font-size: 1.2rem;
  }
  .projectDescription {
    font-size: 1rem;
  }
}
