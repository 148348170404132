/* 
   Name: Habibur Rahman
   Project: React Landing Page
   Date: October 30, 2023
   File: FAQ.module.css
*/

/* FAQ styling */
.faq {
  background-color: #f5f5f5;
  padding: 20px 0;
  overflow: hidden;
}

/* Styling for the title */
.faqTitle {
  font-size: 2.5rem;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 40px;
  transition: color 0.3s;
  text-align: center;
}

/* FAQ container */
.faqContainer {
  max-width: 800px;
  margin: 0 auto;
}

/* FAQ list */
.faqList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* FAQ item */
.faqItem {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
}

.faqItem.open {
  transform: scaleY(1.05);
}

/* Styling for FAQ questions */
.faqQuestion {
  font-size: 1.5rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqIcon {
  font-size: 1.5rem;
}

/* Styling for FAQ answers */
.faqAnswer {
  font-size: 1.2rem;
  color: #777;
  display: none;
}

/* FAQ item click */
.faqItem.open .faqAnswer {
  display: block;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .faqList {
    flex-direction: column;
  }
}

/* Responsive styles for mobile */
@media (max-width: 480px) {
  .faqTitle {
    font-size: 2rem;
  }

  .faqQuestion {
    font-size: 1.3rem;
  }

  .faqIcon {
    font-size: 1.3rem;
  }

  .faqAnswer {
    font-size: 1.1rem;
  }
}
